class BAIDates{
    public static getYears() {
        const year = new Date().getFullYear();
        return Array.from(
            { length: year - 1900 },
            (value, index) => year - index);
    }

    public static getCarYearRegistrations() {
        const year = new Date().getFullYear() + 1;
        const upto = year - 21
        return Array.from(
            { length: year - upto },
            (value, index) => year - index);
    }

    public static getPolicyStart() {
        const year = new Date().getFullYear() + 1;
        const upto = year - 2
        return Array.from(
            { length: year - upto },
            (value, index) => year - index);
    }

    public static getRegisteredYear(year: number) {
        const yearStart = year + 1;
        const yearEnd = yearStart - 4;
        return Array.from(
            { length: yearStart - yearEnd },
            (value, index) => yearStart - index);
    }

    public static getYearDOB() {
        const year = new Date().getFullYear() - 18;
        return Array.from(
            { length: year - 1900 },
            (value, index) => year - index);
    }

    public static getValidYear() {
        const year = new Date().getFullYear();
        return Array.from(
            { length: 10 },
            (value, index) => year + index);
    }

    public static getDays() {
        return Array.from({length: 31}, (_, i) => i + 1);
        
    }
}

export default BAIDates;