<template>
    <div class="page-title-block d-flex align-items-center flex-wrap justify-content-between page-title-container">
        <div class="page-title mb-0 ">
            <h2 class="mb-0">{{ language == 'en' ? enTitle : arTitle }}</h2>
        </div>
        <div class="page-title-desc" v-show="isShow">
            <h5 class="mb-0">{{ $t('title') }} {{ $t('minute') }}</h5>
        </div>
    </div>
</template>
<script>

export default {
    name: 'TitleBar',

    props: {
        enTitle: {
            required: true,
            type: String
        },
        arTitle: {
            required: true,
            type: String
        },
        isShow: {
            required: true,
            type: Boolean
        }
    },

    data() {
        return {
            language: window.localStorage.getItem('language')
        }
    }
}
</script>
